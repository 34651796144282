@font-face {
  font-family: "segoe";
  src: local("segoe"), url(./assets/fonts/SegoeUI.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "segoe-bold";
  src: local("segoe-bold"),
    url(./assets/fonts/SegoeUIBlack.ttf) format("truetype");
  font-display: swap;
}

html,
body,
#root,
#root > div {
  width: 100% !important;
}

body {
  background-color: #00001e;
  color: white;
  font-family: "segoe";
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.logo {
  /* margin-bottom: 64px; */
  margin-bottom: 56px;
  left: 80px;
  /* top: 64px; */
  top: 40px;
}

.logo img {
  /* height: 64px; */
  height: 56px;
  width: 164px;
  border-radius: 0px;
  margin-left: -7px;
}

.container {
  margin: 64px auto;
  margin-bottom: 40px;
  max-width: 1320px;
  overflow-y: hidden;
}

.container-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* gap: 136px; */
  margin-bottom: 50px;
  gap: 136px;
}

.left-inner-container {
  width: 100%;
  /* max-width: 605px; */
  max-width: 640px;
  overflow-x: hidden;
}

.texts {
  font-family: "segoe";
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.coming-soon {
  font-family: "segoe-bold";
  color: white;
  margin-bottom: 16px;
  width: 605px;
  font-size: 24px;
  font-weight: 900;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;

  /* height: 32px; */

  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
}

.title h1 {
  font-family: "segoe-bold";
  color: #00d5ff;
  margin: 0;
  margin-bottom: 16px;
  font-size: 48px;
  font-weight: 900;
  line-height: 65px;
  letter-spacing: 0em;
  text-align: left;
  max-width: 605px;
  width: 100%;
}

.stay-tuned {
  font-family: "segoe-bold";
}

.right-inner-container {
  max-width: 499px;
  margin-top: 129px;
}

.right-inner-container .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #1c1c41;
  padding: 40px;
  margin-bottom: 40px;
}

.right-inner-container .box h2 {
  font-family: "segoe-bold";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #00d5ff;
  margin-bottom: 32px;
}

.or {
  margin: 0;
}

.right-inner-container .box button {
  height: 48px;
  width: 419px;
  border-radius: 0px;
  padding: 13px 32px 13px 32px;
  font-family: "segoe-bold";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.right-inner-container .box .schedule {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #00d5ff;
  border: 1px solid #00d5ff;
  margin-bottom: 12px;
}

.right-inner-container .box .check {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: transparent;
  border: 1px solid #00d5ff;
  margin-top: 12px;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 12px;
}

.links .link {
  cursor: pointer;

}

.footer {
  margin: 0;
  text-align: center;
  color: #ffffffcc;
}

.hr {
  display: none;
}

@media screen and (max-width: 1440px) {
  .container {
    margin: 64px 80px;
    margin-bottom: 40px;
    overflow-y: auto;
    /* max-width: 1140px; */
  }
}

@media screen and (max-width: 1399px) {
  .container {
    margin: 64px 60px;
    margin-bottom: 40px;
    overflow-y: auto;
    /* max-width: 1140px; */
  }
  .container-inner {
    /* max-width: 1140px; */
    gap: 123px;
  }
  .left-inner-container {
    /* max-width: 640px; */
    max-width: 525px;
  }
  .right-inner-container {
    /* max-width: 432px; */
  }
  .right-inner-container .box button {
    height: 48px;
    width: 352px;
    padding: 13px 32px 13px 32px;
  }
}

@media screen and (max-width: 1199px) {
  .container {
    margin: 64px 30px;
    margin-bottom: 40px;
  }
  .container-inner {
  
    gap: 30px;
  }
  .left-inner-container {
    max-width: 525px;
  }
  .right-inner-container .box {
    padding: 32px;
  }
  .right-inner-container .box h2 {
    width: 307px;
    margin-bottom: 24px;
  }
  .right-inner-container .box button {
    width: 307px;
    padding: 13px 32px 13px 32px;
  }
}

@media screen and (max-width: 991px) {
  .container-inner {
    flex-direction: column;
    gap: 30px;
    margin-bottom: 0;
  }
  .title h1 {
    max-width: unset;
  }
  .left-inner-container {
    width: 100%;
    max-width: unset;
  }
  .right-inner-container {
    position: sticky;
    bottom: 0;
    height: 96px;
    width: 100vw;
    margin-top: 0;
    position: fixed;
    bottom: 0;
  }
  .right-inner-container .box {
    flex-direction: row;
    height: 96px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 16px;
    margin-bottom: 0;
  }
  .right-inner-container h2 {
    display: none;
  }
  .right-inner-container .or {
    display: none;
  }
  .right-inner-container .box button {
    height: 48px;
    width: 346px;
    border-radius: 0px;
    padding: 12px 20px;
    margin-bottom: 0;
  }
  .right-inner-container .box .schedule {
    margin-bottom: 0;
  }
  .right-inner-container .box .check {
    margin-bottom: 0;
    margin-top: 0;
  }
  .links {
    justify-content: center;
    margin-top: 64px;
    margin-bottom: 16px;
  }
  .footer {
    margin-bottom: 40px;
    margin-bottom: 96px;
    margin-bottom: 136px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    /* max-width: 516px; */
    margin: 24px 30px;
  }
  .container-inner {
    /* max-width: 516px; */
    flex-direction: column;
    margin-bottom: 0;
  }
  .coming-soon {
    font-size: 18px;
  }
  .title {
    font-size: 32px;
  }
  .logo {
    margin-left: -30px;
    margin-bottom: 40px;
  }
  .logo img {
    height: 40px;
    margin-left: 0;
  }
  .left-inner-container {
    /* max-width: 516px; */
  }

  .right-inner-container .box {
    flex-direction: row;
    height: 96px;
    width: 100vw;
    justify-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    gap: 16px;
    /* position: absolute; */
    /* left: 0px;
    top: 883px; */
    margin-bottom: 0;
  }
  .right-inner-container .box button {
    height: 48px;
    /* width: 250px; */
    /* width: 346px; */
    width:100%;
    padding: 0;
    margin-bottom: 0;
  }
  .right-inner-container .box .schedule {
    margin-left: 30px;
  }
  .right-inner-container .box .check {
    margin-right: 30px;
  }
  .links {
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 16px;
  }
  .footer {
    /* margin-bottom: 40px; */
  }
}

@media screen and (max-width: 650px) {
  /* .right-inner-container .box button {
    width: 270px;
  } */
}

@media screen and (width: 576px) {
  /* .right-inner-container .box button {
    width: 250px;
  } */

  /* .hr {
    display: block;
    background: #00001e;
      height: 5px;
      width: 500px;
      border: 1px solid #00001e;
      position: absolute;
      bottom: 479px;
  } */
}

@media screen and (max-width: 575px) {
  body {
    overflow-x: hidden;
  }
  .container {
    /* max-width: 576px; */
    margin: 24px 20px;
  }
  .container-inner {
    /* max-width: 576px; */
    flex-direction: column;
  }
  .logo {
    margin-left: -30px;
  }
  .title h1 {
    font-size: 32px;
    font-weight: 900;
    line-height: 46px;
    letter-spacing: 0em;
    text-align: left;
  }
  .coming-soon {
    font-size: 18px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0em;
  }
  .left-inner-container {
    /* max-width: 516px; */
  }
  .right-inner-container {
    /* max-width: 100vw; */
    width: 100vw;
    margin-top: 0;
    height: 160px;
  }
  .right-inner-container .box {
    height: 160px;
    /* width: 575px; */
    width: 100vw;
    flex-direction: column;
    justify-content: center;    
    margin-bottom: 0;
  }
  .right-inner-container .box button {
    /* width: 535px; */
    height: 48px;
    width: 90%;
    padding: 0;
    margin-bottom: 0;
  }
  .right-inner-container .box .schedule {
    margin-left: 20px;
    margin-right: 20px;
  }
  .right-inner-container .box .check {
    margin-left: 20px;
    margin-right: 20px;
  }
  .links {
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 16px;
    /* margin-left: 30px; */
  }
  .footer {
    margin-left: 24px;
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 500px) {
  .right-inner-container .box button {
    /* width: 420px; */
    /* margin-left: -70px; */
  }
  .links {
    margin-left: 0;
  }
  .footer {
    margin-left: 0;
    margin-bottom: 240px;
  }
  /* .hr {
    display: block;
    background: #00001e;
      height: 5px;
      width: 500px;
      border: 1px solid #00001e;
      position: absolute;
      bottom: 462px;
  } */
}

@media screen and (max-width: 425px) {
  .right-inner-container .box button {
    /* width: 380px; */
  }
  /* .hr {
    display: block;
    background: #00001e;
      height: 5px;
      width: 500px;
      border: 1px solid #00001e;
      position: absolute;
      bottom: 360px;
  } */
}

@media screen and (max-width: 375px) {
  .right-inner-container .box button {
    /* width: 335px; */
  }
}